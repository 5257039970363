<template>
    <div class="title">Keeper</div>
    <div class="info">
        <div class="fields">
            <div class="field">
                <div>Прослойка для синхронизации от компании <b>CARBIS</b></div>
                <p>
                    <span class="button-box" @click="sync">Запусть Синхронизацию</span>
                </p>
                <p><a href="https://wiki.carbis.ru/external/доставка/api/extv2" target="_blank">Документация</a></p>
            </div>
            <div class="field">
                <SelectBox
                    v-model="data['keeper_store_id']"
                    label="Склад по-умолчанию"
                    :options="this.stores"
                    @change="save"
                ></SelectBox>
            </div>
            <div class="field">
                <SelectBox
                    v-model="data['keeper_price_id']"
                    label="Цена по-умолчанию"
                    :options="this.prices"
                    @change="save"
                ></SelectBox>
            </div>
        </div>

        <div>
            <history :items="history"></history>
        </div>
    </div>
</template>

<script>
import { DictionariesService } from "../../services/dictionaries.service";
import { SyncService } from "../../services/sync.service";
import SelectBox from "../fields/SelectBox";
import History from "../History";

export default {
    name: "Keeper",
    components: {History, SelectBox},
    methods: {
        save() {
            this.syncService
                .saveKeeper(this.data)
                .then(response => {
                    if (response.success) {
                        console.log('saved');
                    }
                });
        },
        sync() {
            this.syncService.initKeeper()
                .then(response => {
                    this.history = response.history;
                })
        }
    },
    computed: {
        stores() {
            let list = {};
            this.dictionariesService.stores.map(store => {
                list[store.id] = store.name;
            })
            return list;
        },
        prices() {
            let list = {};
            this.dictionariesService.prices.map(price => {
                list[price.id] = price.name;
            })
            return list;
        }
    },
    data() {
        return {
            dictionariesService: DictionariesService,
            syncService: SyncService,
            history: [],
            data: {
                storeId: null,
                priceId: null
            }
        }
    },
    created() {
        this.syncService.getKeeper()
            .then(response => {
                if (response.data) {
                    this.data['keeper_store_id'] = response.data['keeper_store_id'];
                    this.data['keeper_price_id'] = response.data['keeper_price_id'];
                    this.history = response.history;
                }
            })
    }
}
</script>

<style scoped>

</style>