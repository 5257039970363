import { reactive } from "vue";
import HttpService from "./http.service";

export const SyncService = reactive({
    getKeeper() {
        return HttpService.get('sync/getKeeper');
    },
    saveKeeper(data) {
        return HttpService.post('sync/saveKeeper', data);
    },
    initKeeper() {
        return HttpService.get('sync/initKeeper');
    },
})