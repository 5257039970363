<template>
    <div class="sidebar">
        <div class="title">Синхронизации</div>
        <ul class="list-navigation">
            <li
                :class="{current: isActive('keeper')}"
                @click="setActive('keeper')"
            >Keeper</li>
        </ul>
    </div>
    <div class="content">
        <keeper v-if="isActive('keeper')"></keeper>
    </div>
</template>

<script>
import Keeper from "../components/sync/Keeper";
export default {
    name: "Sync",
    components: {Keeper},
    methods: {
        isActive(key) {
            return this.active === key;
        },
        setActive(key) {
            this.active = key;
        }
    },
    data() {
        return {
            active: 'keeper'
        }
    }
}
</script>

<style scoped>

</style>